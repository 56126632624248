import { styled } from '@liftfoils/styles'
import { SectionProductFeaturesProps } from '@liftfoils/models'
import { FC } from 'react'
import { PortableText, Link, Media } from '@liftfoils/components'
import { validateLink } from '../Helpers/validateLink'

const Wrap = styled('div', {
  $py: 'L',
})

const Title = styled('h2', {
  lift_font: 'heading02',
  color: '$gray300',
  '@md': {
    maxWidth: '50%',
  },
  '.highlighted': {
    color: '$gray500',
  },
})

const ButtonWrappr = styled('div', {
  mt: '$7',
  '@md': {
    mt: '$0',
  },
})

const Header = styled('div', {
  $hasContainerMX: true,
  display: 'flex',
  flexDirection: 'column',
  '@md': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  '& + div': {
    $mt: 'L',
  },
})
const Slider = styled('div', {
  display: 'flex',
  overflowX: 'scroll',
  overflowY: 'hidden',
  scrollSnapType: 'x mandatory',
  scrollbarWidth: 'none',
  width: '100%',
  position: 'relative',
  scrollPaddingLeft: '$containerMargin',
  scrollPaddingRight: '$containerMargin',
})
const FeaturesWrap = styled('div', {
  px: '$containerMargin',
  display: 'grid',
  gridAutoColumns: '280px',
  gridAutoFlow: 'column',
  gridGap: '$8',
  '@lg': {
    gridAutoColumns: 'unset',
    gridAutoFlow: 'unset',
    gridGap: '$11',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@xl': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
})

const Feature = styled('div', {
  display: 'grid',
  mb: '$8',
  gridTemplateRows: 'auto 1fr auto',
  flexDirection: 'column',
  scrollSnapAlign: 'start',
  color: '$gray300',
})

const FeatureTitle = styled('h3', {
  lift_font: 'heading03',
  py: '$6',
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderColor: '$gray100',
  '.highlighted': {
    color: '$gray500',
  },
})

const FeatureDescription = styled('div', {
  lift_font: 'body02',
  pt: '$8',
  '.highlighted': {
    color: '$gray500',
  },
  '@lg': {
    pr: '10%',
  },
})

const FeatureImage = styled('div', {
  mt: '$8',
  borderRadius: '$r1',
  overflow: 'hidden',
  '@lg': {
    mt: '$10',
  },
})

export const SectionProductFeatures: FC<SectionProductFeaturesProps> = ({
  title,
  buttonLabel,
  buttonLink,
  features,
  priority,
}) => {
  return (
    <Wrap>
      <Header>
        {title && (
          <Title>
            <PortableText value={title} />
          </Title>
        )}
        {buttonLabel && validateLink(buttonLink) && (
          <ButtonWrappr>
            <Link size={'medium'} {...buttonLink} appearance={'solidGray500'}>
              {buttonLabel}
            </Link>
          </ButtonWrappr>
        )}
      </Header>

      {features && features.length > 0 && (
        <Slider>
          <FeaturesWrap>
            {features.map((feature, index) => {
              return (
                <Feature key={index}>
                  {feature.title && (
                    <FeatureTitle>
                      <PortableText value={feature.title} />
                    </FeatureTitle>
                  )}
                  {feature.description && (
                    <FeatureDescription>
                      <PortableText value={feature.description} />
                    </FeatureDescription>
                  )}
                  {feature.image && (
                    <FeatureImage>
                      <Media
                        {...feature.image}
                        layout={'responsive'}
                        hardcropRatio="landscape3"
                        sizes="(min-width: 1280px) 342px, (min-width: 740px) 33vw, 100vw"
                        priority={priority}
                        alt={feature.image.alt ?? ''}
                      />
                    </FeatureImage>
                  )}
                </Feature>
              )
            })}
          </FeaturesWrap>
        </Slider>
      )}
    </Wrap>
  )
}
